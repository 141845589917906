<template>
  <div class="flex flex-col justify-center">
    <div class="bg-stone-white py-2 hidden md:block">
      <CommonSupport></CommonSupport>
    </div>
    <div class="bg-stone-white">
      <div class="relative md:pt-5">
        <NuxtImg
          :src="result.hero_banner.permalink"
          class="md:absolute top-0 z-[1] w-full min-h-[240px] md:max-h-[520px] h-[240px] md:h-[90%] flex align-center object-cover object-right -mt-[1.2em] md:-mt-0"
          alt=""
          width="250"
          height="250"
          format="avif"
        />
        <NuxtLayout name="center">
          <div
            class="shadow-[0px_0px_15px_rgba(0,0,0,0.16)] rounded-lg bg-white -mt-24 md:mt-0 p-[20px] md:p-8 xl:p-16 md:max-w-[64%] relative z-[2]"
          >
         
            <h1
              class="text-[27px] text-left md:text-[37px] lg:text-[47px] font-bold my-2 lineHeightCustom"
            >
              {{ result.hero_title }}
            </h1>
            <ul
              class="tracking-[0.19px] md:tracking-[0.22px] leading-6 text-dark-blue/75"
              v-if="result.hero_text.length > 0"
            >
            
              <li
                class="lineHeight__li md:text-lg flex items-baseline text-[#213756ba] md:gap-x-3 gap-x-1 mb-2"
                v-for="(item, index) in result.hero_text"
                :key="index"
              >
                <div class="w-4"> <NuxtImg src="/icons/checkmark.svg" width="12px" height="12px" > </NuxtImg></div>
                <div class="w-full">{{ item }} </div>
              </li>
            </ul>
            <div class="flex flex-col items-center mt-10 md:mt-8 xl:mt-16">
              <NuxtLinkLocale
                :locale="switchBlogLocale()"
                class="w-full md:w-auto"
                to="/get-started"
              >
                <BaseButton
                  class="md:w-80 w-full h-16 mx-0 text-lg"
                  :text="$t('common.btnGetStarted')"
                />
              </NuxtLinkLocale>
              <div class="flex flex-wrap justify-center my-4 items-center">
                <CommonStarsRating
                  :color="starsColor"
                  :value="customerReview.average_review"
                />
                <NuxtLinkLocale :locale="switchBlogLocale()" to="/reviews">
                  <span class="text-sm whitespace-nowrap underline ml-2">
                    {{
                      $t("reviewBlock.basedOnText", {
                        totalReviews: customerReview.total_reviews,
                      })
                    }}
                  </span>
                </NuxtLinkLocale>
              </div>
            </div>
          </div>
        </NuxtLayout>
      </div>
      <NuxtLayout name="center">
        <div class="pt-12 xl:pt-20 md:pb-20 xl:pb-28 justify-center">
          <CommonUSPBlock :itemList="uspitems.data"></CommonUSPBlock>
        </div>
      </NuxtLayout>
    </div>
    <NuxtLayout name="center">
      <CommonCompanyBlock class="my-12 md:my-20"></CommonCompanyBlock>
    </NuxtLayout>
    <NuxtLayout
      name="center"
      class="w-full mb-0 md:mb-20"
      :mobilePadding="false"
    >
      <h2 class="text-2xl hidden md:block mb-12 md:mb-12">
        {{ $t("getStarted.howWeCanHelp") }}
      </h2>
      <CommonHelpYou />
    </NuxtLayout>

    <div class="bg-secondary-lighter">
      <NuxtLayout name="center" class="pt-10 md:pt-16 xl:pt-20 pb-16">
        <CommonHowItWorks :display-find-clinic-button="true" />
      </NuxtLayout>
    </div>

    <NuxtLayout name="center" :mobilePadding="false">
      <ProductReview
        class="mt-10 md:mt-20 md:mb-20"
        :reviewList="reviewResult.data"
        :totalReview="reviewResult.meta"
        totalReviewPosition="top"
      ></ProductReview>
    </NuxtLayout>

    <div class="bg-secondary-lighter" v-if="products.length > 0">
      <NuxtLayout name="center" class="pt-12 md:pt-20 pb-12">
        <CommonMostPopularTests
          :products="products"
          title=""
          view-detail-button-title=""
          view-all-button-title=""
        >
        </CommonMostPopularTests>
      </NuxtLayout>
    </div>

    <NuxtLayout name="center" class="w-full">
      <CommonLiveChatBlock class="w-full"></CommonLiveChatBlock>
    </NuxtLayout>

    <div class="flex bg-stone-white mb-12 md:mb-20 lg:mb-28 pb-6 md:pb-0">
      <div class="flex flex-col gap-8 md:gap-10 w-full md:mx-auto">
        <CommonContentBlock
          v-for="(content_block, index) in result.content_blocks"
          :key="index"
          :contentData="content_block"
          :index="index"
        ></CommonContentBlock>
      </div>
    </div>

    <CommonLatestBlogs
      v-if="globalStore.siteSettings.display_blog == true"
      category=""
      title=""
      button-title=""
    >
    </CommonLatestBlogs>
  </div>
</template>

<script setup>
import { useGlobalStore } from "~/stores/global";
const customerReview = ref();
const result = ref();
const products = ref();
const starsColor = ref("#00a1aa");

const globalStore = useGlobalStore();
const { data: homeData } = await useHomePage();

if (homeData.value) {
  result.value = homeData.value?.data;
  customerReview.value = homeData.value?.meta;
  products.value = result.value.most_popular_tests;
}

const { data: reviewResult } = await useBlockReview(1);
const { data: uspitems } = await useUSPItems(1);

useMetaTitles(result.value, "home");
</script>

<style>
.translate__card {
  &:hover {
    > div {
      border-radius: 0.5rem;
      transform: translate(-1px, -2px);
      box-shadow: 0px 2px 4px 0px rgba(37, 55, 84, 0.25);
    }
  }
}

.lineHeightCustom {
  line-height: 34px;
}
@media only screen and (min-width: 768px) {
  .lineHeightCustom {
    line-height: inherit;
  }
  .lineHeight__li {
    line-height: inherit;
  }
}


</style>
