<template>
  <div
    class="shadow-[0px_0px_10px_rgba(0,0,0,0.16)] rounded-[7px] items-center px-4 md:px-8 py-4"
  >
    <div class="xl:flex-nowrap flex-wrap hidden md:flex items-center">
      <span class="basis-full font-semibold">{{ $t("homePage.featuredIn") }}</span>
      <NuxtImg
        loading="lazy"
        :src="item.icon"
        class="md:mx-6 md:mx-8 mx-2 my-4 h-8 w-auto md:max-w-full md:h-auto"
        v-for="item in items"
        :key="item.id"
        :width="item.width"
        :height="item.height"
        :alt="item.alt"
      />
    </div>
    <div class="flex flex-wrap md:hidden space-y-8 justify-between">
      <span class="basis-full font-semibold">{{ $t("homePage.featuredIn") }}</span>
      <NuxtImg
        loading="lazy"
        :src="item.icon"
        :class="item.size"
        v-for="item in items"
        :key="item.id"
        :alt="item.alt"
      />
    </div>
  </div>
</template>
<script setup>
const { t } = useI18n();
const items = ref([
  {
    icon: "/img/press_logo_channel.svg",
    alt: t("homePage.featuredInLogo.company1"),
    size: "h-8",
    width: "36",
    height: "50",
  },
  {
    icon: "/img/financial_times.svg",
    alt: t("homePage.featuredInLogo.company2"),
    size: "h-9 w-9",
    width: "65",
    height: "65",
  },
  {
    icon: "/img/independent_logo.svg",
    alt: t("homePage.featuredInLogo.company3"),
    size: "h-8 w-52",
    width: "228",
    height: "35",
  },
  {
    icon: "/img/cosmopolitan.svg",
    alt: t("homePage.featuredInLogo.company4"),
    size: "",
    width: "132",
    height: "24",
  },
  {
    icon: "/img/huffpost.svg",
    alt: t("homePage.featuredInLogo.company5"),
    size: "",
    width: "176",
    height: "20",
  },
]);
</script>
